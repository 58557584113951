import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>YFTEL - Soluciones para ISPs</title>
                <meta name="description" content="YFTEL - Monetiza tu Red con plataformas de control de Acceso" />
                <meta name="og:title" property="og:title" content="YFTEL - Incrementa la facturacion reduce morosidad"></meta>
                <meta name="twitter:card" content="YFTEL - Los usuarios Felices se quedan!"></meta>
                <link rel="canonical" href="https://www.yftelperu/"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
            </Helmet>
        </div>
    )
}

export default SEO
